<template>
  <div class="flex w-full justify-center">
    <p-loader v-if="loading"></p-loader>
    <p-card v-else class="bg-white p-4 flex flex-1 overflow-y-scroll rounded-md">
      <PCardTitle> 
        <span v-html="$tk('Audit.Title')"></span>
      </PCardTitle>
        
      <PCardContent 
        class="text-sm overflow-y-visible flex-grow-0"
        v-html="$tk('Audit.Introduction')"
      ></PCardContent>
      
      <PCardContent class="overflow-y-visible flex-grow-0">
        
        <form @submit.prevent="submitInventory">
          <div v-for="(product, index) in poolingProducts" :key="index">
            <div
              class="mt-1 mb-2 py-2 px-3 border rounded-md flex items-center w-full"
              :class="{ 
                'bg-gradient-to-b from-gray-50 to-gray-200  border-gray-300': product.quantity === 0, 
                'bg-green-100 border-green-500': product.quantity 
              }"
            >
              <img :src="`${assetsBaseUrl}${product.productImage}`" style="width: 69px" />

              <div class="ml-4">
                <div class="font-medium">
                  {{ product.productName }}
                </div>
              </div>

              <div class="flex-1 flex items-center justify-end">
                <div>
                  <PNumber
                    class="w-24"
                    :max="Infinity"
                    :disabled="saving"
                    :step="1"
                    v-model="product.quantity"
                  />
                </div>
              </div>
            </div>
          </div>

          <PButton 
            class="float-right mt-4" 
            :loading="saving" 
            type="submit"            
          >
            <span v-html="submitText"></span>
          </PButton>

        </form>
      </PCardContent>

      <PCardContent class="overflow-y-visible flex-grow-0">

        <transition 
          enter-active-class="transform ease-out duration-300 transition"
          enter-class="opacity-0"
          enter-to-class="opacity-100"
          leave-active-class="transition ease-in duration-100"
          leave-class="opactity-100"
          leave-to-class="opacity-0"
        >
          <PTable 
            :hover="false" 
            @delete="remove" 
            :cols="cols" 
            :data="countsSorted"
          ></PTable>

        </transition>

      </PCardContent>

    </p-card>
  </div>
</template>

<script>

import { mapGetters } from "vuex"
import { get, filter, map, orderBy } from "lodash"
import api from "../api"
import Vue from "vue"

export default {

  name: "Audit",
  
  data () {
    return {
      products: [],
      cols: [
        { text: this.$tk("Common.General.Date"), value: "created", sortable: true, type: "date"},
        { text: this.$tk("Common.General.Id"), value: "productId", sortable: false },
        { text: this.$tk("Common.General.Product"), value: "productName", sortable: false },
        { text: this.$tk("Audit.InboundVolume"), value: "quantityInbound", sortable: false, align: "right" },
        { text: this.$tk("Audit.YourInventory"), value: "quantity", sortable: false, align: "right"},
        { text: this.$tk("Audit.OutboundVolume"), value: "quantityOutbound", sortable: false, type: "id", align: "right"},
        { text: this.$tk("Audit.CountedVolume"), value: "quantityOrdered", sortable: false, align: "right" },
        { text: this.$tk("Audit.Difference"), value: "quantityDiff", sortable: false, align: "right" },
        { text: this.$tk("Audit.RegisteredBy"), value: "createdByName", sortable: false, class:"whitespace-no-wrap" },
        { text: this.$tk("Audit.Confirmed"), value: "ok", sortable: false, align: "center" },
        { text: this.$tk("Common.General.Comment"), value: "comment", sortable: false },
        { text: "", value:"ok", type: "delete", sortable: false }
      ],
      saving: false,
      counts: [],
      loading: true
    }
  },

  computed: {

    ...mapGetters (["location"]),
    
    poolingProducts () {
      return filter(this.products, (product) => {
        return product.isPooling === true
      })
    },

    countsSorted () {      
      return orderBy(this.counts, ['created'], ['desc'])
    },

    assetsBaseUrl () {
      return this.$appConfig.assetsBaseUrl
    },

    submitText () {
      return this.saving 
      ? this.$tk("Common.Actions.Saving")
      : this.$tk("Common.Actions.Save")
    }

  },
  methods: {

    async submitInventory () {

      this.saving = true

      try {

        await Promise.all(        
          
          map(this.poolingProducts, async (product) => {
            
            if (product.quantity > 0){
              
              await api.inventoryCountPost(
                this.location.customerId, 
                this.location.id, 
                product.productId, 
                product.quantity
              )
              
              product.quantity = 0
            }
          })
        )

        this.$notifySuccess(this.$tk("Audit.ReportingSaved", true))

      } catch (e) {

        this.$notifyError(
          this.$tk("Audit.ErrorSavingOneOrMoreReportings", true), 
          this.$formatApiError(e)
        )

      } 
      
      await this.init()

      this.saving = false
    },

    async remove (value) {
      
      try {
        
        this.counts = map(this.counts, count => {
          if (count.id === value.id) {
            Vue.set(count, "visible", false)
          }
          return count
        })

        await api.inventoryCountDelete(value.id)
        
        this.$notifySuccess(this.$tk("Audit.ReportingDeleted", true))

      }
      catch (e) {
        
        this.$notifyError(
          this.$tk("Audit.CouldNotDeleteReporting", true), 
          this.$formatApiError(e)
        )
        
        this.counts = map(this.counts, (count) => {
          if (count.id === value.id) {
            Vue.set(count, "visible", true)
          }
          return count
        })
      }
    },

    async init () {

      const inventoryCount = await api.inventoryCountGet(
        this.location.customerId, 
        this.location.id
      )
      
      this.products = map(inventoryCount.products, product => {
        product.quantity = 0
        return product
      })

      this.counts = map(inventoryCount.counts, count => {
        return {
          ...count,
          createdByName: get(count, "createdBy.name", "")
        }
      })

    }
  },

  async created () {

    await this.init ()

    this.loading = false

  }

}

</script>